
import { Dialog, DialogTitle, DialogActions, DialogContentText, TextField, Radio, Button, DialogContent, ListItemText, Typography, Divider, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import Save from '@mui/icons-material/Save';
import Box from "@material-ui/core/Box";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { uploadSweepSamplePictureFile } from '../../services/FileService';
import ImgsViewer from "react-images-viewer";
import { uploadPreformDrawingFile, getSavedFile } from '../../services/FileService';
// import { Radio } from '@mui/icons-material';




function SweepSetSampleInfoDialog(props) {
    const { openDialog, onDialogClose, sweepSetSampleInfoList } = props
    const [alertBox, setAlertBox] = useState("");
    const [newSweepSetSampleInfoList, setNewSweepSetSampleInfoList] = useState([]);
    
    const [viewImage, setViewImage] = useState(false);
    const [imageSrc, setImageSrc] = useState(''); //for thumbnail if required
    const [imageForViewer, setImageForViewer] = useState([]);

    const [saving, setSaving] = useState(false);


    const { t } = useTranslation();

    
    const useStyles = makeStyles((theme) => ({
        root: {
            flex: 1,
            backgroundColor: 'paper',
        },
        dialog: {
            width: '400px',
            display: 'flex'
        },
        dialogBox: {
            display: 'flex',
            alignItems: 'center'
        },
        dialogText: {
            flex: '0.4'
        },
        dialogInput: {
            flex: '0.5'
        },
        formControl: {
            margin: theme.spacing(2),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(2),
        },
        noWrapCell: {
            whiteSpace: 'nowrap'
        }
    }));

    const handleClose = () => {
        onDialogClose(null);
    };

    const handleSave = () => {
        onDialogClose(newSweepSetSampleInfoList);
    };

    const classes = useStyles();

    useEffect(() => {
        const copyList = [...sweepSetSampleInfoList]

        // getSampleImages().then((res) => {
        //     setNewSweepSetSampleInfoList(res);
        // });
        setNewSweepSetSampleInfoList(copyList);

    }, [sweepSetSampleInfoList]);

    const getSampleImages = async () => {
        let copyList = [...sweepSetSampleInfoList];
        // copyList.forEach((item) => {
        // let copyList = sweepSetSampleInfoList.map((item) => {
        for (let i = 0; i < copyList.length; i++) {

        
            if (copyList[i].picturePath) {
                
                    getSavedFile(copyList[i].picturePath).then((data) => {
                        if (data) {
                            let image = `data:image/jpeg;base64,${data}`;
                            // setImageSrc(image); //for thumbnail if required
                            copyList[i].imageSrc = image;
                            
                            /**
                             * for ImgsViewer
                             */
                            let imageArray = [];
                            let imageForViewer = { src: image };
                            imageArray.push(imageForViewer);
                            // item.imageArray = imageArray;
                            // item.imageName = item.picturePath;
                            
                            // setImageForViewer(imageArray);

                            // return {...item, imageArray: imageArray};
                            copyList[i].imageArray = imageArray;
                            copyList[i].imageName = copyList[i].picturePath;
                        }

                    });
                    
                
            }
        };

        return copyList;
    }
    

	
	const handleClosureMaterialChange = (event, index) => {
	    let newList = newSweepSetSampleInfoList.map((sample, i) => {
	        if (i === index) {
	            return {...sample, closureMaterial: event.target.value}
	        }
	        else {
	            return sample;
	        }
	    });

	    setNewSweepSetSampleInfoList(newList);
	}

	const handleClosureDesignChange = (event, index) => {
	    let newList = newSweepSetSampleInfoList.map((sample, i) => {
	        if (i === index) {
	            return {...sample, closureDesign: event.target.value}
	        }
	        else {
	            return sample;
	        }
	    });

	    setNewSweepSetSampleInfoList(newList);
	}

	const handleLabelMaterialChange = (event, index) => {
	    let newList = newSweepSetSampleInfoList.map((sample, i) => {
	        if (i === index) {
	            return {...sample, labelMaterial: event.target.value}
	        }
	        else {
	            return sample;
	        }
	    });

	    setNewSweepSetSampleInfoList(newList);
	}

	const handleLabelDesignChange = (event, index) => {
	    let newList = newSweepSetSampleInfoList.map((sample, i) => {
	        if (i === index) {
	            return {...sample, labelDesign: event.target.value}
	        }
	        else {
	            return sample;
	        }
	    });

	    setNewSweepSetSampleInfoList(newList);
	}
//------------------------------------------------------
    const handleCanEndSizeChange = (event, index) => {
	    let newList = newSweepSetSampleInfoList.map((sample, i) => {
	        if (i === index) {
	            return {...sample, canEndSize: event.target.value}
	        }
	        else {
	            return sample;
	        }
	    });

	    setNewSweepSetSampleInfoList(newList);
	}
    const handleCanGaugeChange = (event, index) => {
	    let newList = newSweepSetSampleInfoList.map((sample, i) => {
	        if (i === index) {
	            return {...sample, canGauge: event.target.value}
	        }
	        else {
	            return sample;
	        }
	    });

	    setNewSweepSetSampleInfoList(newList);
	}
    const handleCanVolumeChange = (event, index) => {
	    let newList = newSweepSetSampleInfoList.map((sample, i) => {
	        if (i === index) {
	            return {...sample, canVolume: event.target.value}
	        }
	        else {
	            return sample;
	        }
	    });

	    setNewSweepSetSampleInfoList(newList);
	}
    const handleCanStyleChange = (event, index) => {
	    let newList = newSweepSetSampleInfoList.map((sample, i) => {
	        if (i === index) {
	            return {...sample, canStyle: event.target.value}
	        }
	        else {
	            return sample;
	        }
	    });

	    setNewSweepSetSampleInfoList(newList);
	}
    const handleCanColorsChange = (event, index) => {
	    let newList = newSweepSetSampleInfoList.map((sample, i) => {
	        if (i === index) {
	            return {...sample, canColors: event.target.value}
	        }
	        else {
	            return sample;
	        }
	    });

	    setNewSweepSetSampleInfoList(newList);
	}
    const handleCanEndColorChange = (event, index) => {
	    let newList = newSweepSetSampleInfoList.map((sample, i) => {
	        if (i === index) {
	            return {...sample, canEndColor: event.target.value}
	        }
	        else {
	            return sample;
	        }
	    });

	    setNewSweepSetSampleInfoList(newList);
	}
    const handleCanTabColorChange = (event, index) => {
	    let newList = newSweepSetSampleInfoList.map((sample, i) => {
	        if (i === index) {
	            return {...sample, canTabColor: event.target.value}
	        }
	        else {
	            return sample;
	        }
	    });

	    setNewSweepSetSampleInfoList(newList);
	}
    const handleCanCoatingFinishChange = (event, index) => {
	    let newList = newSweepSetSampleInfoList.map((sample, i) => {
	        if (i === index) {
	            return {...sample, canCoatingFinish: event.target.value}
	        }
	        else {
	            return sample;
	        }
	    });

	    setNewSweepSetSampleInfoList(newList);
	}
    const handleCanManufacturerChange = (event, index) => {
	    let newList = newSweepSetSampleInfoList.map((sample, i) => {
	        if (i === index) {
	            return {...sample, canManufacturer: event.target.value}
	        }
	        else {
	            return sample;
	        }
	    });

	    setNewSweepSetSampleInfoList(newList);
	}


	
    const handleUpcTextChange = (event, index) => {        
        let newList = newSweepSetSampleInfoList.map((sample, i) => {
            if (i === index) {
                return {...sample, upcText: event.target.value}
            }
            else {
                return sample;
            }
        });

        setNewSweepSetSampleInfoList(newList);
    }

    const handleSpecificationNumChange = (event, index) => {
        let newList = newSweepSetSampleInfoList.map((sample, i) => {
            if (i === index) {
                return {...sample, specificationNum: event.target.value}
            }
            else {
                return sample;
            }
        });

        setNewSweepSetSampleInfoList(newList);
    }

    const handleExpirationDateChange = (event, index) => {
        let newList = newSweepSetSampleInfoList.map((sample, i) => {
            if (i === index) {
                return {...sample, expirationDate: event.target.value}
                // }                
            }
            else {
                return sample;
            }
        });

        setNewSweepSetSampleInfoList(newList);
    }
    const setPictureChange = (picturePath, index) => {
        let newList = newSweepSetSampleInfoList.map((sample, i) => {
            if (i === index) {
                // if (event.target.value === "true") {
                //     return {...sample, isRepresentative: true}
                // }
                // else {
                return {...sample, picturePath: picturePath}
                // }                
            }
            else {
                return sample;
            }
        });

        setNewSweepSetSampleInfoList(newList);
    }

    const handleManufacturingDateChange = (event, index) => {
        let newList = newSweepSetSampleInfoList.map((sample, i) => {
            if (i === index) {
                return {...sample, manufacturingDate: event.target.value}
            }
            else {
                return sample;
            }
        });

        setNewSweepSetSampleInfoList(newList);
    }


	
	
	
	
    const handleIsRepresentativeChange = (event, index) => {
        let newList = newSweepSetSampleInfoList.map((sample, i) => {
            if (i === index) {
                if (event.target.value === "true") {
                    return {...sample, isRepresentative: true}
                }
                else {
                    return {...sample, isRepresentative: false}
                }                
            }
            else {
                return sample;
            }
        });

        setNewSweepSetSampleInfoList(newList);
    }

    const handlepreformDrawingNameClick = (event) => {
        setViewImage(true);
    }

    const handlePreformDrawingChange = (event, index) => {
        // setPreformDrawing(event.target.files[0]);
        // setPreformDrawingName(event.target.files[0].name);
        // setIsPreformDrawingPicked(true);
        setSaving(true);
        let types = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
        // if (isPreformDrawingPicked) {
        let preformDrawing = event.target.files[0];
        let preformDrawingName = event.target.files[0].name

        if (types.indexOf(preformDrawing.type.toLowerCase()) > -1) {
            const uploadPreformDrawing = async () => {
                try {
                    await uploadSweepSamplePictureFile(preformDrawing).then((res) => {
                        console.log(res)
                        if (res.key) {
                            // setPreformDrawingPath(res.key);
                            setPictureChange(res.key, index);

                        } 
                        setSaving(false);
                        // if (res.image) {
                        //     // let base64ToString = Buffer.from(res.image, "base64").toString()
                        //     let image = `data:image/jpeg;base64,${res.image}`; //
                        //     let imageArray = [];
                        //     let imageForViewer = { src: image };
                        //     imageArray.push(imageForViewer);
                        //     setImageForViewer(imageArray);
                        //     setImageSrc(image);
                        //     setIsPreformDrawingPicked(false);
                        // }
                    });
                }
                catch (err) {
                    // setIsPreformDrawingPicked(false)
                    setSaving(false);
                    console.err(err)
                }
            }
            uploadPreformDrawing();
        }
        else {
            // setIsPreformDrawingPicked(false)
            alert(t("image_type_error"));
            setSaving(false);
        }
            
        // }
    };


    const handleImageClose = (event) => {
        setViewImage(false);
    }

    const onBrowseClick = (event) => {
        event.target.value = null;
    };

    const getPictureName = (path) => {
        // let x = path.split("/").pop()
        let filename = path.split('\\').pop().split('/').pop()
        return filename;
    }

    console.log(newSweepSetSampleInfoList)

    return (

        <div>
            <Dialog open={openDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="xl" minHeight="xl" scroll='paper'>
                <DialogTitle id="alert-dialog-title">Sweep Set Samples Info</DialogTitle>
                {/* NOTE: selectedRow && -> checks if selectedRow is defined before rendering the data */}
                {/* {sweepSetSampleInfoList && ( */}
                    <DialogContent>
                        {alertBox}
                            <Table> 
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.noWrapCell}><strong>ID</strong></TableCell>
                                        <TableCell className={classes.noWrapCell}><strong>UPC Text</strong></TableCell>
                                        <TableCell className={classes.noWrapCell}><strong>Specification Number</strong></TableCell>
                                        <TableCell className={classes.noWrapCell}><strong>Expiration Date</strong></TableCell>
                                        <TableCell className={classes.noWrapCell}><strong>Manufacturing Date</strong></TableCell>

										<TableCell className={classes.noWrapCell}><strong>Closure Material</strong></TableCell>
										<TableCell className={classes.noWrapCell}><strong>Closure Design</strong></TableCell>
										<TableCell className={classes.noWrapCell}><strong>Label Material</strong></TableCell>
										<TableCell className={classes.noWrapCell}><strong>Label Design</strong></TableCell>

                                        <TableCell className={classes.noWrapCell}><strong>Label Info</strong></TableCell>
										<TableCell className={classes.noWrapCell}><strong>Label Color (s)</strong></TableCell>
										<TableCell className={classes.noWrapCell}><strong>Closure Info</strong></TableCell>
										<TableCell className={classes.noWrapCell}><strong>Closure Color</strong></TableCell>
                                        <TableCell className={classes.noWrapCell}><strong>Bottle Color</strong></TableCell>
										<TableCell className={classes.noWrapCell}><strong>Finish Type</strong></TableCell>
										<TableCell className={classes.noWrapCell}><strong>Observation Notes</strong></TableCell>
										<TableCell className={classes.noWrapCell}><strong>(future use)</strong></TableCell>
                                        <TableCell className={classes.noWrapCell}><strong>(future use)</strong></TableCell>

                                        <TableCell><strong>Picture</strong></TableCell>
                                        <TableCell><strong>Representative</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {newSweepSetSampleInfoList.length > 0 &&
                                        newSweepSetSampleInfoList.map((sample, index) => (
                                            <TableRow>
                                                <TableCell>
                                                    {sample.uniqueId}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField value={sample.upcText ? sample.upcText : ""} onChange={(e) => handleUpcTextChange(e, index)} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" required />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField value={sample.specificationNum} onChange={(e) => handleSpecificationNumChange(e, index)} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" required />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField value={sample.expirationDate} onChange={(e) => handleExpirationDateChange(e, index)} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" required />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField value={sample.manufacturingDate} onChange={(e) => handleManufacturingDateChange(e, index)} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" required />
                                                </TableCell>
												<TableCell>
												    <TextField value={sample.closureMaterial} onChange={(e) => handleClosureMaterialChange(e, index)} className={classes.dialogInput} margin="dense" id="closureMaterial" type="input" variant="outlined" required />
												</TableCell>
												<TableCell>
												    <TextField value={sample.closureDesign} onChange={(e) => handleClosureDesignChange(e, index)} className={classes.dialogInput} margin="dense" id="closureDesign" type="input" variant="outlined" required />
												</TableCell>
												<TableCell>
												    <TextField value={sample.labelMaterial} onChange={(e) => handleLabelMaterialChange(e, index)} className={classes.dialogInput} margin="dense" id="labelMaterial" type="input" variant="outlined" required />
												</TableCell>
												<TableCell>
												    <TextField value={sample.labelDesign} onChange={(e) => handleLabelDesignChange(e, index)} className={classes.dialogInput} margin="dense" id="labelDesign" type="input" variant="outlined" required />
												</TableCell>

                                                <TableCell>
												    <TextField value={sample.canEndSize} onChange={(e) => handleCanEndSizeChange(e, index)} className={classes.dialogInput} margin="dense" id="canEndSize" type="input" variant="outlined" required />
												</TableCell>
                                                <TableCell>
												    <TextField value={sample.canGauge} onChange={(e) => handleCanGaugeChange(e, index)} className={classes.dialogInput} margin="dense" id="canGauge" type="input" variant="outlined" required />
												</TableCell>
                                                <TableCell>
												    <TextField value={sample.canVolume} onChange={(e) => handleCanVolumeChange(e, index)} className={classes.dialogInput} margin="dense" id="canVolume" type="input" variant="outlined" required />
												</TableCell>
                                                <TableCell>
												    <TextField value={sample.canStyle} onChange={(e) => handleCanStyleChange(e, index)} className={classes.dialogInput} margin="dense" id="canStyle" type="input" variant="outlined" required />
												</TableCell>
                                                <TableCell>
												    <TextField value={sample.canColors} onChange={(e) => handleCanColorsChange(e, index)} className={classes.dialogInput} margin="dense" id="canColors" type="input" variant="outlined" required />
												</TableCell>
                                                <TableCell>
												    <TextField value={sample.canEndColor} onChange={(e) => handleCanEndColorChange(e, index)} className={classes.dialogInput} margin="dense" id="canEndColor" type="input" variant="outlined" required />
												</TableCell>
                                                <TableCell>
												    <TextField value={sample.canTabColor} onChange={(e) => handleCanTabColorChange(e, index)} className={classes.dialogInput} margin="dense" id="canTabColor" type="input" variant="outlined" required />
												</TableCell>
                                                <TableCell>
												    <TextField value={sample.canCoatingFinish} onChange={(e) => handleCanCoatingFinishChange(e, index)} className={classes.dialogInput} margin="dense" id="canCoatingFinish" type="input" variant="outlined" required />
												</TableCell>
                                                <TableCell>
												    <TextField value={sample.canManufacturer} onChange={(e) => handleCanManufacturerChange(e, index)} className={classes.dialogInput} margin="dense" id="canManufacturer" type="input" variant="outlined" required />
												</TableCell>
                                                <TableCell>
                                                
                                                    {/* <div className={classes.dialogBox} style={{ display: 'flex', flexDirection:"column"}}> */}
                                                        {/* {sample.imageSrc && sample.imageSrc !== '' ? <Tooltip title="Click to view" > */}
                                                            {/* <div onClick={handlepreformDrawingNameClick} >
                                                            
                                                                <label style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '60%', maxWidth: '60%' }} >{preformDrawingName}</label>
                                                                <img src={sample.imageSrc} alt={sample.imageName}></img>
                                                            </div>
                                                        </Tooltip> : null}

                                                        <ImgsViewer
                                                            imgs={sample.imageArray}
                                                            isOpen={viewImage}
                                                            onClose={handleImageClose}
                                                        />
                                                        <div className={classes.dialogBox}>
                                                            <Button variant="contained" component="label" style={{ flex: '100%', maxWidth: '50%' }}>
                                                                {t("browse_label")}
                                                                <input type="file" accept="image/*" hidden className={classes.dialogText} onChange={(e) => handlePreformDrawingChange(e, index)} onClick={onBrowseClick} id="preformDrawingName" />
                                                            </Button>
                                                        </div>
                                                    </div> */}

                                                    <div className={classes.dialogBox} style={{ display: 'flex', flexDirection:"column" }}>
                                                        {sample.picturePath && <label>{getPictureName(sample.picturePath)}</label>}
                                                        <div className={classes.dialogBox} style={{ display: 'flex', flex: '20%', justifyContent:"center" }}>
                                                            <Button variant="contained" component="label" style={{ flex: '100%', maxWidth: '50%'}}>
                                                                {t("browse_label")}
                                                                <input type="file" accept="image/*" hidden className={classes.dialogText} onChange={(e) => handlePreformDrawingChange(e, index)} onClick={onBrowseClick} id="preformDrawingName" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <label>
                                                        True
                                                        <Radio
                                                            checked={sample.isRepresentative === true}
                                                            onChange={(e) => handleIsRepresentativeChange(e, index)}
                                                            value="true"
                                                            name="isRepresentative"
                                                        />
                                                    </label>
                                                    
                                                    <label>
                                                        False
                                                        <Radio
                                                            checked={sample.isRepresentative === false}
                                                            onChange={(e) => handleIsRepresentativeChange(e, index)}
                                                            value="false"
                                                            name="isRepresentative"
                                                        />
                                                    </label>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                            
                    </DialogContent>
                {/* )} */}
                <DialogActions>
                    <Tooltip title="Save">
                        <IconButton disabled={saving} onClick={handleSave}>
                            <Save />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Close">
                        <IconButton onClick={handleClose}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default SweepSetSampleInfoDialog
