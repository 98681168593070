/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { disableSweepJob,disableJob, getAllRegisteredJobs } from '../../services/RegisterJobService';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import GenericTable from '../GenericTable';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import AddEditJob from '../RegisterSamples/AddEditJob';
import AddEditSweepJob from '../SweepJobs/AddEditSweepJob';
import { getAllSweepJobs } from '../../services/SweepJobService';
import ViewIcon from "@material-ui/icons/Visibility";
import IconButton from '@material-ui/core/IconButton';
import UserService from '../../keycloak'

import OpenIcon from '@mui/icons-material/OpenInBrowser';
import OpenReportIcon from '@mui/icons-material/AssignmentOutlined';
import { Assessment } from '@mui/icons-material';


const SweepJobs = (props) => {

    const [rows, setRows] = useState([]);
    const [dense, setDense] = useState(false);
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const { t } = useTranslation();
    // let text = t("register_samples_title");
    let text = "DTV Requests";
   
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});


    // // A custom progress indicator for progress column....
    // const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    //     height: 7,
    //     borderRadius: 5,
    //     backgroundColor: theme.palette.grey[300],
    // }));

    const sortByAddDate = (a, b) => {
        if (a.addDate < b.addDate) return 1
        else if (a.addDate > b.addDate) return -1
        else return 0
    }

    const sortByDueDate = (a, b) => {
        if (a.dueDate < b.dueDate) return 1
        else if (a.dueDate > b.dueDate) return -1
        else return 0
    }

    const fetchRows = () => {
        getAllSweepJobs().then((res) => {
            if (!(res instanceof Array)) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            progressFix(res)
            res.sort(sortByAddDate)
            setRows(res)
        })
    }


    useEffect(() => {
        fetchRows();
    }, [])



    /**
     * Returns a JSX Element that contains the due date, styled with a color according to the expiration date (red if expired, inherit otherwise).
     * @param row the row that contains a dueDate attribute
     * @returns a JSX element
     */
    const getDueDate = (row) => {
        // const style = new Date() > new Date(row.dueDate) ? {color: "red"} : {color: "inherit"};
        return <span>{new Date(row.dueDate).toLocaleString("en-UK").split(',')[0]}</span>
    }


    

    const columns = [
        { selector: row => row.name, sortable: true, name: t("Name"), type: "text", key: "name", grow: 0.25 },
		{ selector: row => row.longCode, sortable: true, name: t("Request ID"), type: "text", key: "longCode", grow: 1.3 },
        { selector: row => row.shortCode, sortable: true, name: t("Short code"), type: "text", key: "shortCode", grow: 0.2 },
        // { selector: row => new Date(row.addDate).toLocaleString("en-UK").split(',')[0], sortable: true, sortFunction: sortByAddDate, name: t("add_date_label"), type: "date", key: "addDate", grow: 0.4 },
        { selector: row => getDueDate(row), sortable: true, sortFunction: sortByDueDate, name: t("due_date_label"), type: "date", key: "dueDate", grow: 0.34 },
        { selector: row => row.updatedBy, sortable: true, name: t("Added by"), type: "text", key: "addedBy", grow: 0.34 },
        { selector: row => row.numSets, sortable: true, name: t("Number of Sets"), type: "text", key: "numSets", grow: 0.3 },

		{ cell: row => {return row.numSets > 0 ? <Tooltip followCursor={true} title={t("Open Report")}><IconButton component={Link} to={`/reports/?open=true&jobId=${row.id}&sweepsetId=-1`}> <OpenIcon /> </IconButton></Tooltip> : null}, grow: 0.05, omit: UserService.hasRole(['DTV'])},
        { cell: row => {return row.numSets > 0 ? <Tooltip followCursor={true} title={t("Open Testing")}><IconButton component={Link} to={`/testing/?sweepTestsTable=true&jobId=${row.id}`}> <OpenReportIcon /> </IconButton></Tooltip> : null}, grow: 0.05, omit: UserService.hasRole(['DTV'])},
        { cell: row => {return row.numSets > 0 ? <Tooltip followCursor={true} title={t("Generate Excel")}><IconButton component={Link} onClick={
                                                                                                                    () => {
                                                                                                                        var name = encodeURIComponent(row.name);
                                                                                                                        window.open(`/?downloads=true&jobId=${row.id}&name=${name}&sweep=${true}`);
                                                                                                                    }
                                                                                                                }>
                                                                                    <Assessment />
                                                                                </IconButton></Tooltip> : null}, grow: 0.05, omit: UserService.hasRole(['DTV'])
        }
    ];

    

    const progressFix = (res) => {
        res.forEach((r) => {
            if (!r.testsCompleted) return;
            if (!r.numberOfTests) return;
            if (r.testsCompleted > r.numberOfTests) r.numberOfTests = r.testsCompleted
        })
    }
  


    const handleDelete = async (selected) => {
        if (window.confirm('Are you sure you wish to delete ?')) {
            let promises = selected.map((item) => { return disableSweepJob(item.id) })
            var temp = rows.filter((r) => { return selected.includes(r) === false })
            setRows(temp)
            await Promise.all(promises)
            fetchRows()
        }
    }

    const Dummy = (props) => { return "" }

    
    return openAdd ? <AddEditSweepJob openAdd={openAdd} openEdit={openEdit} setOpenAdd={setOpenAdd} setOpenEdit={setOpenEdit} selectedItem={selectedItem}  rows={rows} setRows={setRows} /> :

        <GenericTable
        dense={dense}
        keyColumn={{
            key: "jobID",
            name: t("job_id_label")
        }}
        setDense={setDense}
        updateTable={() => { }}
        Dialog={Dummy}
        rows={rows}
        columns={columns}
        text={text}
        createSweepJobText = { t('create_sweep_job')}
        deleteCallback={handleDelete}
       
        parentAdd={[openAdd, setOpenAdd]}
        parentEdit={[openEdit, setOpenEdit]}
        selectedItem={[selectedItem, setSelectedItem]}
        useDialog={false} />;

}






export default SweepJobs;
